import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetAccountsListQuery, useRevokeAccessToAccountMutation, useUpdateProfileMutation } from 'services/profile';

import { selectProfile } from 'state/selectors/profile/profileSelector';

import { TablePagination } from 'types/pagination';

import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';

import { AccountsSection } from './accounts';
import { AccountDataRow } from './types';
import { transformAccountsDataToTableFormat } from './utils';

export const AccountsSectionContainer = () => {
  const { profile } = useSelector(selectProfile);

  const [pagination, setPagination] = useState<TablePagination>(getDefaultPagination());
  const { data, isFetching } = useGetAccountsListQuery({
    pagination: undecoratePagination(pagination),
  });

  const [updateProfile] = useUpdateProfileMutation();
  const [revokeAccessToAccountByUuid] = useRevokeAccessToAccountMutation();

  const setAccountAsDefault = (accountId: string) => {
    updateProfile({
      defaultAccountUuid: accountId,
    });
  };

  const revokeAccessToAccount = (accountId: string) => {
    revokeAccessToAccountByUuid({
      accountUuid: accountId,
      userUuid: profile.uuid,
    });
  };

  useEffect(() => {
    if (data?.data?.pagination) {
      setPagination(decoratePagination(data?.data?.pagination));
    }
  }, [data?.data?.pagination]);

  const accounts: AccountDataRow[] = transformAccountsDataToTableFormat(data?.data?.data);

  const onChangePage = (page: number) => {
    setPagination({
      ...pagination,
      page,
    });
  };

  const onChangeRowsPerPage = (itemsPerPage: number) => {
    setPagination({
      ...pagination,
      itemsPerPage,
      page: 1,
    });
  };

  return (
    <AccountsSection
      pagination={pagination}
      accounts={accounts}
      isLoading={isFetching}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      setAccountAsDefault={setAccountAsDefault}
      revokeAccessToAccount={revokeAccessToAccount}
    />
  );
};
