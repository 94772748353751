/* eslint-disable react/no-array-index-key */
import { Typography } from 'components';
import React, { useState } from 'react';
import { Outlet, Link as RouterLink, useLocation } from 'react-router-dom';

import LogoCollapsed from 'assets/images/logoCollapsed.svg';
import LogoExpanded from 'assets/images/logoExpanded.svg';

import OptionallyVisible from 'components/optionallyVisible';

import { LayoutWithSidebarProps } from '../types';
import {
  ChevronRightIcon,
  CollapsedLogoImg,
  DrawerContent,
  ExpandedLogoImg,
  FlexBox,
  HideMenuButtonText,
  HideMenuWrapper,
  Link,
  ListMenu,
  ListMenuItem,
  ListMenuItemButton,
  ListMenuItemText,
  LogoWrapper,
  Main,
  MenuDrawer,
  MenuIcon,
} from './layoutWithSidebar.desktop.styles';

export const LayoutWithSidebarDesktop: React.FC<LayoutWithSidebarProps> = ({ layoutMenuItems }) => {
  const [open, setOpen] = useState(true);
  const location = useLocation();

  const toggleDrawer = () => setOpen(!open);

  const drawerContent = (
    <DrawerContent>
      <LogoWrapper>
        <ExpandedLogoImg open={open} src={LogoExpanded} alt="Shift4 Logo" width="161" height="18" />
        <CollapsedLogoImg open={open} src={LogoCollapsed} alt="Shift4 Logo" width="48" height="55" />
      </LogoWrapper>
      <ListMenu>
        {layoutMenuItems?.map(({ label, icon: Icon, to, openInNewWindow }, index) => (
          <ListMenuItem key={index}>
            <Link to={to} component={RouterLink} target={openInNewWindow ? '_blank' : ''}>
              <ListMenuItemButton selected={location.pathname === to}>
                <MenuIcon component={Icon} />
                <OptionallyVisible visible={open}>
                  <ListMenuItemText primary={<Typography variant="subtitle1">{label}</Typography>} />
                </OptionallyVisible>
              </ListMenuItemButton>
            </Link>
          </ListMenuItem>
        ))}
        <HideMenuWrapper>
          <ListMenuItemButton onClick={toggleDrawer}>
            <ChevronRightIcon open={open} />
            <OptionallyVisible visible={open}>
              <HideMenuButtonText primary={<Typography variant="subtitle1">Hide</Typography>} />
            </OptionallyVisible>
          </ListMenuItemButton>
        </HideMenuWrapper>
      </ListMenu>
    </DrawerContent>
  );

  return (
    <FlexBox>
      <MenuDrawer variant="permanent" open={open}>
        {drawerContent}
      </MenuDrawer>
      <Main shrink={open}>
        <Outlet />
      </Main>
    </FlexBox>
  );
};

export default LayoutWithSidebarDesktop;
