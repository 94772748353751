import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

export const useSearch = (debounceTimeout: number) => {
  const [search, setSearch] = useState<string>('');
  const handleSearch = (search: string) => setSearch(search);
  const debouncedHandleSearch = useCallback(debounce(handleSearch, debounceTimeout), [setSearch]);

  return { search, setSearch: debounceTimeout ? debouncedHandleSearch : handleSearch };
};
