import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from '@mui/material';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Delimeter } from 'components/elements/delimeter';

import { Filter, FilterSection, FilterType } from 'types/filters';

import { Button } from '../../buttons';
import IconFilter from '../../icons/filter';
import OptionallyVisible from '../../optionallyVisible';
import { AmountFilter } from '../amount/amount';
import { CheckboxFilter } from '../checkbox/checkbox';
import { CurrencyFilter } from '../currency/currency';
import { DateFilter } from '../date/date';
import { RadioFilter } from '../radio/radio';
import { RemoteSelectFilter } from '../select/remoteSelect';
import { SelectFilter } from '../select/select';
import {
  CheckboxFilterPassedProps,
  CurrencyFilterPassedProps,
  DateFilterPassedProps,
  RadioFilterPassedProps,
  RemoteSelectFilterPassedProps,
  SelectFilterPassedProps,
} from '../types';
import { FilterItem } from './filterItem';
import { LABELS } from './keys';
import { ButtonContent, ButtonLabel, Controls, FilterButton, FilterPanel, Filters, Header, Section, Title } from './styles';

interface FilterDropdownProps<T extends { [key: string]: any }> {
  filters: FilterSection[];
  values: T;
  onChange: (filters: Partial<T>) => void;
  onClear: () => void;
}

export const getFilterComponent = (filter: Filter<unknown>, value: any, onChange: (value: any) => void) => {
  if (filter.type === FilterType.Checkbox) {
    const filterOptions = filter as Filter<CheckboxFilterPassedProps>;
    return <CheckboxFilter value={value || []} onChange={onChange} options={filterOptions.options} />;
  }

  if (filter.type === FilterType.CurrencySelect) {
    const filterOptions = filter as Filter<CurrencyFilterPassedProps>;
    return <CurrencyFilter value={value} onChange={onChange} options={filterOptions.options} multiple={filterOptions.multiple} />;
  }

  if (filter.type === FilterType.Select) {
    const filterOptions = filter as Filter<SelectFilterPassedProps>;
    return (
      <SelectFilter
        value={value}
        placeholder={filterOptions.placeholder}
        onChange={onChange}
        options={filterOptions.options}
        multiple={filterOptions.multiple}
      />
    );
  }

  if (filter.type === FilterType.RemoteSelect) {
    const filterOptions = filter as Filter<RemoteSelectFilterPassedProps>;
    return (
      <RemoteSelectFilter
        value={value}
        onChange={onChange}
        multiple={filterOptions.multiple}
        placeholder={filterOptions.placeholder}
        getItems={filterOptions.getItems}
      />
    );
  }

  if (filter.type === FilterType.Amount) {
    return <AmountFilter value={value || {}} onChange={onChange} />;
  }

  if (filter.type === FilterType.Radio) {
    const filterOptions = filter as Filter<RadioFilterPassedProps>;
    return <RadioFilter value={value || []} onChange={onChange} options={filterOptions.options} />;
  }

  const filterOptions = filter as Filter<DateFilterPassedProps>;
  return <DateFilter value={value} onChange={onChange} presets={filterOptions.presets} />;
};

export const FilterDropdown = <T extends { [key: string]: any }>({ filters = [], values, onChange, onClear }: FilterDropdownProps<T>) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>();
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [localValues, setLocalValues] = useState<T>(values);
  const { t } = useTranslation();

  useEffect(() => {
    setLocalValues(values);
    const filtersToCollapse = difference(Object.keys(selected), Object.keys(values));
    setSelected(omit(selected, filtersToCollapse));
  }, [values]);

  const handleRef = (ref: HTMLButtonElement) => {
    buttonRef.current = ref;
  };

  const openDropdown = () => {
    setOpen(!isOpen);
  };

  const closeDropdown = () => {
    setOpen(false);
  };

  const clearFilters = () => {
    setSelected({});
    onClear();
  };

  const saveFilters = () => {
    onChange(localValues);
    closeDropdown();
  };

  const toggleSelect = (filter: Filter<unknown>) => () => {
    setSelected({
      ...selected,
      [filter.key]: !selected[filter.key],
    });

    if (selected[filter.key]) {
      handleFilterChange(filter)(undefined);
    }
  };

  const handleFilterChange = (filter: Filter<unknown>) => (value: any) => {
    setLocalValues({
      ...localValues,
      [filter.key]: value,
    });
  };

  const areFiltersChanged = !isEqual(localValues, values);

  const countOfAppliedFilters = Object.entries(values).filter(([key, value]) => !isEmpty(value)).length;
  const buttonTitle = countOfAppliedFilters ? t(LABELS.TITLE_ACTIVE, { count: countOfAppliedFilters }) : t(LABELS.TITLE);
  const isButtonActive = isOpen || Boolean(countOfAppliedFilters);

  return (
    <>
      <FilterButton active={isButtonActive} flat forwardRef={handleRef} onClick={openDropdown}>
        <ButtonContent>
          <IconFilter />
          <ButtonLabel>{buttonTitle}</ButtonLabel>
        </ButtonContent>
      </FilterButton>
      <Popper open={isOpen} anchorEl={buttonRef.current} placement="bottom-start">
        <ClickAwayListener onClickAway={closeDropdown}>
          <FilterPanel>
            <Header>
              <Title>{t(LABELS.TITLE)}</Title>
              <Controls>
                <Button secondary onClick={clearFilters}>
                  {t(LABELS.CLEAR)}
                </Button>
                <Button primary onClick={saveFilters} disabled={!areFiltersChanged}>
                  {t(LABELS.SAVE)}
                </Button>
              </Controls>
            </Header>
            <Filters>
              {filters.map((section, index) => (
                <Section key={section.key}>
                  {section.content.map((filter) => (
                    <FilterItem onSelect={toggleSelect(filter)} selected={selected?.[filter.key]} key={filter.key} label={filter.label}>
                      {getFilterComponent(filter, localValues[filter.key], handleFilterChange(filter))}
                    </FilterItem>
                  ))}
                  <OptionallyVisible visible={index < filters.length - 1}>
                    <Delimeter />
                  </OptionallyVisible>
                </Section>
              ))}
            </Filters>
          </FilterPanel>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
