import RoutePath from 'router/path';

export const KEYS = {
  INVITE_ACCEPT_TIMEOUT: 100,
  INVITE_KEY_QUERY_PARAM: 'key',
  TRANSLATION_NAMESPACE: 'page.accountSettings',
  ROUTE_MATCH: `${RoutePath.AccountSettings.Root}/:route`,
  ROUTE_MATCH_ID: `${RoutePath.AccountSettings.ById}/:route`,
  TABS: {
    GENERAL: 'general',
    PAYMENT_METHODS: 'payment-methods',
    API_KEYS: 'api-keys',
    USER_MANAGEMENT: 'users',
  },
};

export const LABELS = {
  S4C_USER_BREADCRUMBS: [
    ['page.orders.s4cUserBreadcrumbsPartnerAccounts', 'Partner Accounts'],
    ['page.orders.s4cUserBreadcrumbsAccountSettings', 'Account Settings'],
  ],
  PARTNER_USER_BREADCRUMBS: [['page.orders.partnerUserBreadcrumbs', 'Account Settings']],
  PAGE_TITLE: ['page.accountSettings.title', 'Account Settings'],
  TABS: {
    GENERAL: ['page.accountSettings.tabs.general', 'General'],
    API_KEYS: ['page.accountSettings.tabs.apiKeys', 'API Keys'],
    PAYMENT_METHODS: ['page.accountSettings.tabs.apiKeys', 'Payment Methods'],
    USER_MANAGEMENT: ['page.accountSettings.tabs.userManagement', 'User management'],
  },
};
