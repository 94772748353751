import { PageWrapper } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetOrderQuery } from 'services/orders';

import LoadingIndicator from 'components/loadingIndicator';

import RoutePath from 'router/path';

import { selectUserLevel } from 'state/slices/userSlice';

import { OrderWithdrawalStatus } from 'types/order';

import { useRedirectOnAccountSwitch } from '../../utils/useRedirectOnAccountSwitch';
import { LABELS } from './keys';
import { OrderDetails } from './orderDetails';
import { hasCardFailure, hasExchangeFailure, hasUserS4cRole } from './utils';

export const OrderViewContainer = () => {
  const { t } = useTranslation();
  useRedirectOnAccountSwitch(RoutePath.Orders.Root);

  const params = useParams();
  const { uuid } = params;

  const { data: order, isLoading } = useGetOrderQuery(uuid!, {
    skip: !uuid,
  });

  const userLevel = useSelector(selectUserLevel);
  const isS4cUser = hasUserS4cRole(userLevel);

  const shouldShowExchangeDetailsSection = isS4cUser && !hasCardFailure(order?.data);
  const shouldShowWithdrawalDetailsSection = !hasCardFailure(order?.data) && !hasExchangeFailure(order?.data);
  const shouldShowExtendedDepositDetails = order?.data?.order.onChainStatus === OrderWithdrawalStatus.Confirmed;
  const shouldShowExchangeInUsdDetailsSection = !isS4cUser && !hasCardFailure(order?.data);

  const hasExtendedAccess = isS4cUser;

  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  if (isLoading) {
    return (
      <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
        <LoadingIndicator padded />
      </PageWrapper>
    );
  }

  if (!order?.data) {
    return null;
  }

  return (
    <PageWrapper breadcrumbs={breadcrumbs} pageTitle={t(LABELS.PAGE_TITLE)}>
      <OrderDetails
        orderData={order?.data}
        shouldShowExchangeDetailsSection={shouldShowExchangeDetailsSection}
        shouldShowWithdrawalDetailsSection={shouldShowWithdrawalDetailsSection}
        hasExtendedAccess={hasExtendedAccess}
        shouldShowExtendedDepositDetails={shouldShowExtendedDepositDetails}
        shouldShowExchangeInUsdDetailsSection={shouldShowExchangeInUsdDetailsSection}
        isS4cUser={isS4cUser}
      />
    </PageWrapper>
  );
};
