import styled from '@emotion/styled';

import { Path } from 'components/icons/styles';
import { InputRoot } from 'components/inputs/input/styles';

export const AccountSection = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 8,
  // @ts-ignore
  [InputRoot]: {
    width: 290,
  },
}));

export const Label = styled.span((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));

export const AccountOwnerSection = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 16,
  // @ts-ignore
  [InputRoot]: {
    width: 290,
  },
}));

export const Adornment = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  marginRight: 8,
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));
