import React, { MouseEvent, PropsWithChildren } from 'react';

import { Root, Text } from './styles';
import { ButtonVariant } from './types';

export interface ButtonProps extends PropsWithChildren {
  primary?: boolean;
  secondary?: boolean;
  flat?: boolean;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  className?: string;
  small?: boolean;
  forwardRef?: (ref: HTMLButtonElement) => void;
}

const getButtonVariant = (args: Record<ButtonVariant, boolean | undefined>): ButtonVariant => {
  if (args.primary) {
    return ButtonVariant.Primary;
  }

  if (args.secondary) {
    return ButtonVariant.Secondary;
  }

  if (args.flat) {
    return ButtonVariant.Flat;
  }

  return ButtonVariant.Primary;
};

export const Button = ({ primary, small, secondary, flat, children, onClick, disabled, className, forwardRef }: ButtonProps) => {
  const variant = getButtonVariant({ primary, secondary, flat });
  return (
    <Root ref={forwardRef} small={small} onClick={onClick} disabled={disabled} variant={variant} className={className}>
      <Text>{children}</Text>
    </Root>
  );
};
