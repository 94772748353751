import styled from '@emotion/styled/macro';

export const FormRow = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
}));

export const FieldTitle = styled.div((props) => ({
  minWidth: 150,
  maxWidth: 150,
  fontFamily: props.theme.fonts.primary,
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '16px',
  color: props.theme.colors.inactive,
  display: 'flex',
  alignItems: 'center',
}));

export const FieldValue = styled.div((props) => ({
  width: 290,
  display: 'flex',
  alignItems: 'center',
  fontFamily: props.theme.fonts.primary,
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '16px',
  color: props.theme.colors.primary,
}));
