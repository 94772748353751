import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';

import { OrderSide } from 'types/order';

import { convertValueToDayjs } from 'utils/date';

import { OrderStatusBadge } from '../../../components/orderStatusBadge/orderStatusBadge';
import { OrderTypeBadge } from '../../../components/orderTypeBadge/orderTypeBadge';
import { KEYS, LABELS } from '../../keys';
import { OrderSectionProps } from '../../types';
import { AmountBlock, AmountNumericValue, AmountValue, Delimiter, Item, ItemName, ItemValue, SectionWrapper } from '../styles';
import { formatCryptoCode, formatNumber } from '../utils';

interface OrderSectionPropsWithUserType extends OrderSectionProps {
  isS4cUser: boolean;
}

export const OrderSection = ({ orderData, isS4cUser }: OrderSectionPropsWithUserType) => {
  const { t } = useTranslation();

  const isBuyOrder = orderData.order.side === OrderSide.Buy;

  return (
    <Panel label={t(LABELS.SECTIONS.ORDER.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.ORDER_ID)}</ItemName>
          <ItemValue>{orderData.order.uuid}</ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.order.externalOrderId)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.EXTERNAL_ORDER_ID)}</ItemName>
            <ItemValue>{orderData.order.externalOrderId}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.SIDE)}</ItemName>
          <ItemValue>
            <OrderTypeBadge side={orderData.order.side} />
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.PARTNER_ACCOUNT_ID)}</ItemName>
          <ItemValue>{orderData.order.accountUuid ? orderData.order.accountUuid : '–'}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.DATE)}</ItemName>
          <ItemValue>{convertValueToDayjs(orderData.order.createdAt)?.format(KEYS.DATE_FORMAT)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.STATUS)}</ItemName>
          <ItemValue>
            <OrderStatusBadge status={orderData.order.status} />
          </ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.CRYPTO_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{formatCryptoCode(orderData.order.cryptoCurrencyCode)}</AmountValue>
              <AmountNumericValue>{orderData.order.cryptoAmount}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.FIAT_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{orderData.order.fiatCurrencyCode}</AmountValue>
              <AmountNumericValue>{formatNumber(orderData.order.fiatAmount)}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.PROCESSING_FEE_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{orderData.order.fiatCurrencyCode}</AmountValue>
              <AmountNumericValue>{formatNumber(orderData.order.processingFeeFiatAmount)}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.PROCESSING_FEE_PERCENT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountNumericValue>{formatNumber(orderData.order.processingFeePercent)}</AmountNumericValue>
              <AmountValue>%</AmountValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.order.partnerFeeFiatAmount)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.PARTNER_FEE_AMOUNT)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.order.fiatCurrencyCode}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.order.partnerFeeFiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.order.partnerFeePercent)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.PARTNER_FEE_PERCENT)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountNumericValue>{formatNumber(orderData.order.partnerFeePercent)}</AmountNumericValue>
                <AmountValue>%</AmountValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={isBuyOrder}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.NETWORK_FEE)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.order.fiatCurrencyCode}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.order.networkFeeFiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.EXCHANGED_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{orderData.order.fiatCurrencyCode}</AmountValue>
              <AmountNumericValue>{formatNumber(orderData.order.cryptoCostFiatAmount)}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.CRYPTO_PRICE)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountNumericValue>{KEYS.BASE_CURRENCY_UNIT}</AmountNumericValue>
              <AmountValue>{formatCryptoCode(orderData.order.cryptoCurrencyCode)}</AmountValue>
              <AmountValue>{KEYS.APPROX_EQUAL_SYMBOL}</AmountValue>
              <AmountNumericValue>{formatNumber(orderData.order.cryptoPrice)}</AmountNumericValue>
              <AmountValue>{orderData.order.fiatCurrencyCode}</AmountValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <OptionallyVisible visible={isS4cUser}>
          <Delimiter />

          <OptionallyVisible visible={Boolean(orderData.order?.fiatAmountInUSD)}>
            <Item>
              <ItemName>{t(LABELS.SECTIONS.ORDER.FIAT_AMOUNT_IN_USD)}</ItemName>
              <ItemValue>
                <AmountBlock>
                  <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
                  <AmountNumericValue>{formatNumber(orderData.order?.fiatAmountInUSD)}</AmountNumericValue>
                </AmountBlock>
              </ItemValue>
            </Item>
          </OptionallyVisible>

          <OptionallyVisible visible={Boolean(orderData.order?.processingFeeFiatAmountInUSD)}>
            <Item>
              <ItemName>{t(LABELS.SECTIONS.ORDER.PROCESSING_FEE_FIAT_AMOUNT_IN_USD)}</ItemName>
              <ItemValue>
                <AmountBlock>
                  <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
                  <AmountNumericValue>{formatNumber(orderData.order?.processingFeeFiatAmountInUSD)}</AmountNumericValue>
                </AmountBlock>
              </ItemValue>
            </Item>
          </OptionallyVisible>

          <OptionallyVisible visible={Boolean(orderData.order?.partnerFeeFiatAmountInUSD)}>
            <Item>
              <ItemName>{t(LABELS.SECTIONS.ORDER.PARTNER_FEE_FIAT_AMOUNT_IN_USD)}</ItemName>
              <ItemValue>
                <AmountBlock>
                  <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
                  <AmountNumericValue>{formatNumber(orderData.order?.partnerFeeFiatAmountInUSD)}</AmountNumericValue>
                </AmountBlock>
              </ItemValue>
            </Item>
          </OptionallyVisible>
        </OptionallyVisible>
      </SectionWrapper>
    </Panel>
  );
};
