import styled from '@emotion/styled';

import { Button as ButtonComponent } from 'components/buttons';
import { Root as ButtonRoot, Text } from 'components/buttons/styles';
import { IconRefresh } from 'components/icons/refresh';

export const Icon = styled(IconRefresh)(() => ({
  marginRight: 5,
  width: 18,
  height: 18,
}));

export const ButtonRotate = styled(ButtonComponent)((props) => ({
  // @ts-ignore
  [Text]: {
    color: props.theme.colors.accent,
    marginLeft: -4,
  },
}));

export const ButtonClose = styled(ButtonComponent)((props) => ({
  // @ts-ignore
  [Text]: {},
}));

export const ModalControls = styled.div((props) => ({
  // @ts-ignore
  [ButtonRoot]: {
    minWidth: 'unset',
    width: 20,
    height: 20,
    padding: 4,
    span: {
      lineHeight: 0.5,
    },
  },
}));

export const ModalWrapper = styled.div((props) => ({
  minWidth: 441,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 15,
  alignItems: 'flex-start',
}));

export const ModalContent = styled.div((props) => ({
  display: 'flex',
  width: '100%',
  padding: '16px 10px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
}));

export const Divider = styled.div((props) => ({
  width: '100%',
  borderBottom: `1px solid ${props.theme.colors.container.primary}`,
  margin: '20px 0',
}));

export const Buttons = styled.div((props) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 16,
}));

export const QuestionText = styled.div((props) => ({
  fontSize: 16,
  fontWeight: 600,
}));
