import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { Delimeter } from 'components/elements/delimeter';
import IconCross from 'components/icons/cross';
import Modal from 'components/modal/modal';
import { Body, CloseButton, PanelTitle } from 'components/modal/styles';

import { CustomerStatus } from 'types/customer';

import { LABELS } from '../../keys';
import { StatusChangeModalBasicMessage, StatusChangeModalButtons } from './modalComponents';

interface BasicStatusChangeModalProps {
  open: boolean;
  onClose: () => void;
  status: CustomerStatus;
  onSubmit: (nextStatus: CustomerStatus) => Promise<void>;
  isUpdating: boolean;
}

export const BasicStatusChangeModal = ({ open, onClose, status, onSubmit, isUpdating }: BasicStatusChangeModalProps) => {
  const { t } = useTranslation();
  const isCustomerActive = status === CustomerStatus.Active;

  const handleSubmit = () => {
    const nextStatus = isCustomerActive ? CustomerStatus.Blocked : CustomerStatus.Active;
    onSubmit(nextStatus);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Panel
        label={<PanelTitle>{t(LABELS.CHANGE_STATUS.MODAL_TITLE)}</PanelTitle>}
        controls={
          <CloseButton flat onClick={onClose}>
            <IconCross />
          </CloseButton>
        }
      >
        <Body>
          <StatusChangeModalBasicMessage isCustomerActive={isCustomerActive} />
          <Delimeter />
          <StatusChangeModalButtons onClose={onClose} onSubmit={handleSubmit} isUpdating={isUpdating} />
        </Body>
      </Panel>
    </Modal>
  );
};
