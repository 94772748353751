import styled from '@emotion/styled';

export const DesktopCellBlock = styled.div((props) => ({
  whiteSpace: 'nowrap',
}));

export const MobileCellBlock = styled.div((props) => ({
  width: 120,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
