import React from 'react';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { IconDelete } from 'components/icons/delete';

import { DOMAIN_COLUMN_IDS } from '../keys';
import { ActionButtons, RemoveDomainButton } from './styles';

interface makeDomainTableCellParams {
  removeDomain: (domainUuid: string) => Promise<boolean>;
  isRemoving: boolean;
}

export const ActionsTableCell = ({ row, passedProps }: DataTableCellProps) => {
  const { removeDomain, isRemoving } = passedProps!;

  const handleRemove = () => {
    removeDomain(row.id);
  };

  return (
    <TableCell>
      <ActionButtons>
        <RemoveDomainButton flat onClick={handleRemove} disabled={isRemoving}>
          <IconDelete />
        </RemoveDomainButton>
      </ActionButtons>
    </TableCell>
  );
};

export const makeDomainTableCell =
  ({ removeDomain, isRemoving }: makeDomainTableCellParams) =>
  (props: DataTableCellProps) => {
    const { row, column } = props;
    if (column.id === DOMAIN_COLUMN_IDS.ACTIONS) {
      return <ActionsTableCell {...props} passedProps={{ removeDomain, isRemoving }} />;
    }

    return <TableCell>{row[column.id]}</TableCell>;
  };
