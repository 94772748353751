import React from 'react';

import { IconProps } from './types';

const path = [
  'M6.66647 14.6666L6.39981 12.5333C6.25536 12.4777 6.11936 12.411 5.99181 12.3333C5.8638 12.2555 5.73869',
  '12.1721 5.61647 12.0833L3.63314 12.9166L1.7998 9.74992L3.51647 8.44992C3.50536 8.37214 3.4998 8.29703 3.4998',
  '8.22459V7.77459C3.4998 7.70259 3.50536 7.6277 3.51647 7.54992L1.7998 6.24992L3.63314 3.08325L5.61647',
  '3.91659C5.73869 3.8277 5.86647 3.74436 5.9998 3.66659C6.13314 3.58881 6.26647 3.52214 6.39981 3.46659L6.66647',
  '1.33325H10.3331L10.5998 3.46659C10.7442 3.52214 10.8805 3.58881 11.0085 3.66659C11.136 3.74436',
  '11.2609 3.8277 11.3831 3.91659L13.3665 3.08325L15.1998 6.24992L13.4831 7.54992C13.4942 7.6277 13.4998',
  '7.70259 13.4998 7.77459V8.22459C13.4998 8.29703 13.4887 8.37214 13.4665 8.44992L15.1831 9.74992L13.3498',
  '12.9166L11.3831 12.0833C11.2609 12.1721 11.1331 12.2555 10.9998 12.3333C10.8665 12.411 10.7331 12.4777',
  '10.5998 12.5333L10.3331 14.6666H6.66647ZM8.53314 10.3333C9.17758 10.3333 9.72758 10.1055 10.1831 9.64992C10.6387',
  '9.19436 10.8665 8.64436 10.8665 7.99992C10.8665 7.35547 10.6387 6.80547 10.1831 6.34992C9.72758',
  '5.89436 9.17758 5.66659 8.53314 5.66659C7.87758 5.66659 7.32469 5.89436 6.87447 6.34992C6.42469',
  '6.80547 6.19981 7.35547 6.19981 7.99992C6.19981 8.64436 6.42469 9.19436 6.87447 9.64992C7.32469',
  '10.1055 7.87758 10.3333 8.53314 10.3333ZM8.53314 8.99992C8.25536 8.99992 8.01936 8.90259 7.82514',
  '8.70792C7.63047 8.5137 7.53314 8.2777 7.53314 7.99992C7.53314 7.72214 7.63047 7.48614 7.82514',
  '7.29192C8.01936 7.09725 8.25536 6.99992 8.53314 6.99992C8.81092 6.99992 9.04714 7.09725',
  '9.24181 7.29192C9.43603 7.48614 9.53314 7.72214 9.53314 7.99992C9.53314 8.2777 9.43603',
  '8.5137 9.24181 8.70792C9.04714 8.90259 8.81092 8.99992 8.53314 8.99992ZM7.83314 13.3333H9.14981L9.38314',
  '11.5666C9.72758 11.4777 10.0471 11.347 10.3418 11.1746C10.636 11.0026 10.9054 10.7944 11.1498',
  '10.5499L12.7998 11.2333L13.4498 10.0999L12.0165 9.01659C12.072 8.86103 12.1109 8.69703 12.1331',
  '8.52459C12.1554 8.35259 12.1665 8.1777 12.1665 7.99992C12.1665 7.82214 12.1554 7.64703',
  '12.1331 7.47459C12.1109 7.30259 12.072 7.13881 12.0165 6.98325L13.4498 5.89992L12.7998',
  '4.76659L11.1498 5.46659C10.9054 5.21103 10.636 4.99703 10.3418 4.82459C10.0471 4.65259',
  '9.72758 4.52214 9.38314 4.43325L9.16647 2.66659H7.8498L7.61647 4.43325C7.27203 4.52214',
  '6.95269 4.65259 6.65847 4.82459C6.3638 4.99703 6.09425 5.20547 5.8498 5.44992L4.1998',
  '4.76659L3.5498 5.89992L4.98314 6.96659C4.92758 7.13325 4.88869 7.29992 4.86647 7.46659C4.84425',
  '7.63325 4.83314 7.81103 4.83314 7.99992C4.83314 8.1777 4.84425 8.34992 4.86647 8.51659C4.88869',
  '8.68325 4.92758 8.84992 4.98314 9.01659L3.5498 10.0999L4.1998 11.2333L5.8498 10.5333C6.09425',
  '10.7888 6.3638 11.0026 6.65847 11.1746C6.95269 11.347 7.27203 11.4777 7.61647',
  '11.5666L7.83314 13.3333Z',
].join(' ');

export const IconSettings = ({ width = 17, height = 16, color, className, style }: IconProps) => (
  <svg className={className} style={style} width={width} height={height} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
    <path fill={color || 'currentColor'} d={path} />
  </svg>
);
