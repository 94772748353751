import React from 'react';

import { AcceptInvite } from './edit/acceptInvite';
import { AccountSettingsContainer } from './edit/container';
import { APIKeysSectionContainer } from './edit/sections/apiKeys/container';
import { GeneralSectionContainer } from './edit/sections/general/container';
import { LandingSectionContainer } from './edit/sections/landing/container';
import { PaymentMethodsSectionContainer } from './edit/sections/paymentMethods/container';
import { UsersSectionContainer } from './edit/sections/users/container';
import { WidgetSectionContainer } from './edit/sections/widget/container';
import { AccountsListContainer } from './list/container';

export const AccountSettingsRoute = AccountSettingsContainer;
export const PaymentMethodsSectionRoute = PaymentMethodsSectionContainer;
export const APIKeysSectionRoute = APIKeysSectionContainer;
export const GeneralSectionRoute = () => (
  <>
    <GeneralSectionContainer />
    <WidgetSectionContainer />
    <LandingSectionContainer />
  </>
);
export const WidgetSectionRoute = WidgetSectionContainer;
export const LandingSectionRoute = LandingSectionContainer;
export const UsersSectionRoute = UsersSectionContainer;
export const AcceptInviteRoute = AcceptInvite;
export const AccountsListRoute = AccountsListContainer;
