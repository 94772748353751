export const LABELS = {
  CREATE_MODAL: {
    TITLE: ['orderTableCell.layout.createAccountModal.title', 'Create New Account'],
    BUTTONS: {
      CREATE: ['orderTableCell.layout.createAccountModal.buttons.create', 'Create Account'],
      CANCEL: ['orderTableCell.layout.createAccountModal.buttons.cancel', 'Cancel'],
      EDIT: ['orderTableCell.layout.createAccountModal.buttons.edit', 'Edit'],
      CONFIRM: ['orderTableCell.layout.createAccountModal.buttons.confirm', 'Confirm'],
      CLOSE: ['orderTableCell.layout.createAccountModal.buttons.close', 'Close'],
    },
    SECTIONS: {
      ACCOUNT_SECTION: {
        TITLE: ['orderTableCell.layout.createAccountModal.sections.accountSection.title', 'Account Name'],
      },
      ACCOUNT_OWNER_SECTION: {
        TITLE: ['orderTableCell.layout.createAccountModal.sections.accountOwnerSection.title', 'Account Owner Information'],
      },
    },
    FORM_FIELDS: {
      PLACEHOLDERS: {
        ACCOUNT_NAME: ['orderTableCell.layout.createAccountModal.form.accountName.placeholder', 'Enter Account Name'],
        EMAIL: ['orderTableCell.layout.createAccountModal.form.email.placeholder', 'Email'],
        FIRST_NAME: ['orderTableCell.layout.createAccountModal.form.firstName.placeholder', 'First Name'],
        LAST_NAME: ['orderTableCell.layout.createAccountModal.form.lastName.placeholder', 'Last Name'],
      },
    },
    VERIFY_STEP: {
      TITLE: ['orderTableCell.layout.createAccountModal.verifyStep.title', "Please verify the account's information"],
      ACCOUNT_NAME_LABEL: ['orderTableCell.layout.createAccountModal.verifyStep.accountNameLabel', 'Account Name'],
      ACCOUNT_OWNER_LABEL: ['orderTableCell.layout.createAccountModal.verifyStep.accountOwnerLabel', 'Account Owner Information'],
    },
    SUCCESS_STEP: {
      TITLE: ['orderTableCell.layout.createAccountModal.successStep.title', 'Success!'],
      CREATED_LABEL: ['orderTableCell.layout.createAccountModal.successStep.createdLabel', 'successfully created'],
    },
  },
};

export const KEYS = {
  FORM_FIELDS: {
    ACCOUNT_NAME: 'accountName',
    EMAIL: 'username',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
  },
};
