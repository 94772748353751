import { PageWrapper } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { useGetAccountQuery } from 'services/accounts';

import IconBuild from 'components/icons/build';
import IconCreditCard from 'components/icons/creditCard';
import IconCrypto from 'components/icons/crypto';
import IconProfile from 'components/icons/profile';
import LoadingIndicator from 'components/loadingIndicator/loadingIndicator';
import { TabItem } from 'components/tabs';

import RoutePath from 'router/path';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { UserAccessLevel } from 'types/user';

import { AccountSettings } from './accountSettings';
import { KEYS, LABELS } from './keys';

export const AccountSettingsContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // we use two matches to cover `/account` and `/account/:uuid` routes.
  // If they will diverge a lot - we would need to split containers for these cases
  const matchId = useMatch(KEYS.ROUTE_MATCH_ID);
  const match = useMatch(KEYS.ROUTE_MATCH);
  const selectedMatch = matchId || match;
  const { account: selectedAccount } = useSelector(selectAccount);
  const { route } = selectedMatch?.params || {};
  let { uuid } = selectedMatch?.params || {};

  if (match && !uuid) {
    uuid = selectedAccount.uuid;
  }

  const { isLoading } = useGetAccountQuery(uuid!, {
    skip: !uuid,
  });

  const userLevel = useSelector(selectUserLevel);
  const isSupport = userLevel === UserAccessLevel.Support;

  const tabs: TabItem[] = [
    {
      key: KEYS.TABS.GENERAL,
      label: t(LABELS.TABS.GENERAL),
      icon: <IconBuild />,
      onClick: () => navigate(RoutePath.AccountSettings.General),
    },
    ...(!isSupport
      ? [
          {
            key: KEYS.TABS.PAYMENT_METHODS,
            label: t(LABELS.TABS.PAYMENT_METHODS),
            icon: <IconCreditCard />,
            onClick: () => navigate(RoutePath.AccountSettings.PaymentMethods),
          },
          {
            key: KEYS.TABS.API_KEYS,
            label: t(LABELS.TABS.API_KEYS),
            icon: <IconCrypto />,
            onClick: () => navigate(RoutePath.AccountSettings.APIKeys),
          },
        ]
      : []),
    {
      key: KEYS.TABS.USER_MANAGEMENT,
      label: t(LABELS.TABS.USER_MANAGEMENT),
      icon: <IconProfile />,
      onClick: () => navigate(RoutePath.AccountSettings.Users),
    },
  ];

  const selectedTab: TabItem | undefined = tabs.find((tab) => tab.key === route);

  const breadcrumbsStart = uuid
    ? LABELS.S4C_USER_BREADCRUMBS.map((item) => t(item))
    : LABELS.PARTNER_USER_BREADCRUMBS.map((item) => t(item));

  const breadcrumbs = [...breadcrumbsStart, selectedTab?.label].filter(Boolean) as string[];

  if (isLoading) {
    return (
      <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
        <LoadingIndicator padded />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
      <AccountSettings tabs={tabs} selected={selectedTab} />
    </PageWrapper>
  );
};
