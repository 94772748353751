import styled from '@emotion/styled';

import { Button as ButtonComponent } from 'components/buttons';
import { Text } from 'components/buttons/styles';
import { IconVisibility } from 'components/icons/visibility';

export const Icon = styled(IconVisibility)(() => ({
  marginRight: 5,
  width: 18,
  height: 18,
}));

export const RevealedText = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: 34,
}));

export const Button = styled(ButtonComponent)((props) => ({
  // @ts-ignore
  [Text]: {
    color: props.theme.colors.accent,
    marginLeft: -4,
  },
}));
