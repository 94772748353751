import { useNotifications } from 'providers/notifications/useNotifications';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAssetListQuery } from 'services/assets';
import {
  useCreateOrderMutation,
  useGetCardsQuery,
  useGetCustomerBalancesQuery,
  useLazyGetCustomerBalancesQuery,
  useLazyGetOrderPreviewQuery,
  useLazyGetRefundQuoteQuery,
  useMakeRefundMutation,
} from 'services/customers';

import { AssetType } from 'types/assets';
import { Customer, CustomerIPInfo } from 'types/customer';
import { NotificationSeverity } from 'types/notifications';

import { CustomerBalanceList } from './balanceList';
import { LABELS } from './keys';
import { BalanceDataRow } from './types';

interface CustomerOrderListContainerProps {
  customer: Customer & CustomerIPInfo;
}

export const CustomerBalancesListContainer = ({ customer }: CustomerOrderListContainerProps) => {
  const { uuid } = customer;
  const { t } = useTranslation();
  const { pushDialog } = useNotifications();
  const { data: cards, isLoading: areCardsFetching } = useGetCardsQuery(uuid);
  const [fetchOrderQuote, { isFetching: isOrderPreviewFetching, error: orderPreviewError }] = useLazyGetOrderPreviewQuery();
  const [createOrder, { isLoading: isOrderSubmitting }] = useCreateOrderMutation();
  const { data: queryData, isLoading: isFetching } = useGetCustomerBalancesQuery(uuid);
  const [getWithdrawQuote, { isFetching: isQuoteFetching, error: quoteError }] = useLazyGetRefundQuoteQuery();
  const [makeRefund, { isLoading: isRefundSubmitting, error: refundError }] = useMakeRefundMutation();
  const { data: assets = [] } = useGetAssetListQuery();
  const [refresh, lazyQueryResult] = useLazyGetCustomerBalancesQuery();
  const handleRefresh = () => {
    refresh(uuid);
  };

  const getAssetBySymbol = useMemo(
    () => (symbol: string) => {
      return assets.find((item) => item.symbol === symbol);
    },
    [assets],
  );

  const getQuote = async (cryptoCurrencyCode: string) => {
    const asset = getAssetBySymbol(cryptoCurrencyCode);
    if (!asset) {
      throw new Error(t(LABELS.ERRORS.INVALID_ASSET));
    }

    const result = await getWithdrawQuote({
      customerUuid: uuid,
      cryptoCurrencyCode,
      walletTag: asset.tagName || undefined,
    });

    if ('data' in result) {
      return result.data!;
    }

    if ('error' in result) {
      throw new Error(result.error as string);
    }

    return {
      networkFeeUsdAmount: 0,
    };
  };

  const getOrderPreview = async (query: { cryptoCurrencyCode: string; fiatCurrencyCode: string; amount: number }) => {
    const asset = getAssetBySymbol(query.cryptoCurrencyCode);
    if (!asset) {
      throw new Error(t(LABELS.ERRORS.INVALID_ASSET));
    }

    const result = await fetchOrderQuote({
      clientUuid: uuid,
      cryptoCurrencyCode: query.cryptoCurrencyCode,
      fiatCurrencyCode: query.fiatCurrencyCode,
      cryptoAmount: query.amount,
    });

    if ('data' in result) {
      return result.data;
    }

    if ('error' in result) {
      throw new Error(result.error as string);
    }

    return undefined;
  };

  const submitRefund = async ({
    cryptoCurrencyCode,
    walletAddress,
    walletTag,
  }: {
    cryptoCurrencyCode: string;
    walletAddress: string;
    walletTag?: string;
  }) => {
    try {
      const result = await makeRefund({
        clientUuid: uuid,
        cryptoCurrencyCode,
        walletAddress,
        walletTag,
      });

      return { success: 'data' in result };
    } catch (e: unknown) {
      pushDialog({
        severity: NotificationSeverity.error,
        message: e as string,
        title: t(LABELS.ERRORS.TITLE),
      });
    }

    return { success: false };
  };

  const submitOrder = async (quoteId: string, paymentToken: string) => {
    try {
      const result = await createOrder({
        clientUuid: uuid,
        quoteId,
        paymentToken,
      });

      if ('error' in result) {
        if (typeof result.error === 'string') {
          throw result.error;
        } else {
          const [errorMessage] = Object.values(result.error);
          throw errorMessage;
        }
      }

      return { success: 'data' in result };
    } catch (e: unknown) {
      pushDialog({
        severity: NotificationSeverity.error,
        message: e as string,
        title: t(LABELS.ERRORS.ORDER_TITLE),
      });
    }

    return { success: false };
  };

  const { data: refetchData, isFetching: isUpdating } = lazyQueryResult;

  const isLoading = isFetching || isUpdating;
  const data = refetchData || queryData;
  const balances = (data || []).map((item) => ({ id: `${item.asset.symbol}`, ...item })) as BalanceDataRow[];
  const fiatCurrencies = assets.filter((asset) => asset.type === AssetType.Fiat);

  return (
    <CustomerBalanceList
      balances={balances}
      isLoading={isLoading}
      refresh={handleRefresh}
      refundProps={{
        getQuote,
        submitRefund,
        getAsset: getAssetBySymbol,
        quoteError: quoteError as string,
        isQuoteFetching,
        isRefundSubmitting,
        refundError: refundError as string,
      }}
      createOrderProps={{
        cards: cards || [],
        getQuote: getOrderPreview,
        fiatCurrencies,
        isQuoteFetching: isOrderPreviewFetching,
        areCardsFetching,
        getAsset: getAssetBySymbol,
        submitOrder,
        quoteError: orderPreviewError as Record<string, string>,
        isOrderSubmitting,
      }}
    />
  );
};
