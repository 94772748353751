import React from 'react';

import { IconProps } from './types';

const path = [
  'M1.16699 13.3334V11.4667C1.16699 11.089 1.26433 10.7416 1.45899 10.4247C1.65321 10.1083 1.91144',
  '9.86675 2.23366 9.70008C2.92255 9.35564 3.62255 9.09719 4.33366 8.92475C5.04477 8.75275 5.76699',
  '8.66675 6.50033 8.66675C7.23366 8.66675 7.95588 8.75275 8.66699 8.92475C9.3781 9.09719 10.0781',
  '9.35564 10.767 9.70008C11.0892 9.86675 11.3474 10.1083 11.5417 10.4247C11.7363 10.7416 11.8337',
  '11.089 11.8337 11.4667V13.3334H1.16699ZM13.167 13.3334V11.3334C13.167 10.8445 13.031 10.375 12.759',
  '9.92475C12.4865 9.47497 12.1003 9.08897 11.6003 8.76675C12.167 8.83341 12.7003 8.94719 13.2003',
  '9.10808C13.7003 9.26941 14.167 9.46675 14.6003 9.70008C15.0003 9.9223 15.3059 10.1694 15.517 10.4414C15.7281',
  '10.7139 15.8337 11.0112 15.8337 11.3334V13.3334H13.167ZM6.50033 8.00008C5.76699 8.00008 5.13921 7.73897',
  '4.61699 7.21675C4.09477 6.69453 3.83366 6.06675 3.83366 5.33341C3.83366 4.60008 4.09477 3.9723',
  '4.61699 3.45008C5.13921 2.92786 5.76699 2.66675 6.50033 2.66675C7.23366 2.66675 7.86144 2.92786',
  '8.38366 3.45008C8.90588 3.9723 9.16699 4.60008 9.16699 5.33341C9.16699 6.06675 8.90588 6.69453',
  '8.38366 7.21675C7.86144 7.73897 7.23366 8.00008 6.50033 8.00008ZM10.5003 8.00008C10.3781 8.00008',
  '10.2225 7.9863 10.0337 7.95875C9.84477 7.93075 9.68921 7.90008 9.56699 7.86675C9.86699 7.51119',
  '10.0974 7.11675 10.2583 6.68341C10.4197 6.25008 10.5003 5.80008 10.5003 5.33341C10.5003',
  '4.86675 10.4197 4.41675 10.2583 3.98341C10.0974 3.55008 9.86699 3.15564 9.56699 2.80008C9.72255 2.74453',
  '9.8781 2.7083 10.0337 2.69141C10.1892 2.67497 10.3448 2.66675 10.5003 2.66675C11.2337',
  '2.66675 11.8614 2.92786 12.3837 3.45008C12.9059 3.9723 13.167 4.60008 13.167 5.33341C13.167 6.06675',
  '12.9059 6.69453 12.3837 7.21675C11.8614 7.73897 11.2337 8.00008 10.5003 8.00008ZM2.50033 12.0001H10.5003V11.4667C10.5003',
  '11.3445 10.4699 11.2334 10.409 11.1334C10.3477 11.0334 10.267 10.9556 10.167 10.9001C9.56699 10.6001',
  '8.96144 10.375 8.35032 10.2247C7.73921 10.075 7.12255 10.0001 6.50033 10.0001C5.8781 10.0001 5.26144',
  '10.075 4.65033 10.2247C4.03921 10.375 3.43366 10.6001 2.83366 10.9001C2.73366 10.9556 2.65321 11.0334',
  '2.59233 11.1334C2.53099 11.2334 2.50033 11.3445 2.50033 11.4667V12.0001ZM6.50033 6.66675C6.86699',
  '6.66675 7.18099 6.53608 7.44233 6.27475C7.70321 6.01386 7.83366 5.70008 7.83366 5.33341C7.83366',
  '4.96675 7.70321 4.65297 7.44233 4.39208C7.18099 4.13075 6.86699 4.00008 6.50033 4.00008C6.13366 4.00008',
  '5.81988 4.13075 5.55899 4.39208C5.29766 4.65297 5.16699 4.96675 5.16699 5.33341C5.16699',
  '5.70008 5.29766 6.01386 5.55899 6.27475C5.81988 6.53608 6.13366 6.66675 6.50033 6.66675Z',
].join(' ');

export const IconPeople = ({ width = 17, height = 16, color, className, style }: IconProps) => (
  <svg className={className} style={style} width={width} height={height} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
    <path fill={color || 'currentColor'} d={path} />
  </svg>
);
