import { AccountAccessLevel } from 'types/account';
import { RouteSetup, RouteSetupFactory } from 'types/routing';

import PartnerAdministratorRouterSetup from './administrator';
import PartnerOwnerRouterSetup from './owner';
import PartnerViewerRouterSetup from './viewer';

const getRouteSetupMap: Record<AccountAccessLevel, (hasAccount: boolean) => RouteSetup> = {
  [AccountAccessLevel.Owner]: PartnerOwnerRouterSetup,
  [AccountAccessLevel.Administrator]: PartnerAdministratorRouterSetup,
  [AccountAccessLevel.Viewer]: PartnerViewerRouterSetup,
};

export const PartnerRouterSetup: RouteSetupFactory = ({ accountAccessLevel, hasAccounts }): RouteSetup =>
  accountAccessLevel ? getRouteSetupMap[accountAccessLevel as AccountAccessLevel](hasAccounts) : PartnerViewerRouterSetup(hasAccounts);

export default PartnerRouterSetup;
