import dayjs from 'dayjs';

import { convertValueToDayjs } from 'utils/date';

import { Country } from '../../../types/country';
import { KEYS, LABELS } from './keys';

export const formatDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  const now = dayjs();
  const dateValue = convertValueToDayjs(dateString);
  const isSameYear = now.year() === dateValue?.year();
  const format = isSameYear ? KEYS.DATE_FORMAT : KEYS.DATE_FORMAT_WITH_YEAR;
  return convertValueToDayjs(dateString)?.format(format);
};

export const formatBoolean = (value: boolean) => (value ? LABELS.YES : LABELS.NO);

export const getCountry = (countries: Country[], countryCode: string) => {
  if (!countryCode) {
    return null;
  }

  return countries.find((country) => country.isoAlpha2 === countryCode);
};

export const getState = (country: Country | null | undefined, stateCode: string) => {
  if (!country || !stateCode) {
    return null;
  }

  return country.states?.find((state) => state.abbreviation === stateCode);
};
