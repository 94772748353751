export interface CreatedAccount {
  username: string;
  firstName: string;
  lastName: string;
  accountName: string;
}

export enum CreateAccountStep {
  Form = 'form',
  Verify = 'verify',
  Success = 'success',
}
