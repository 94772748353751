import { t } from 'i18next';

import { ColumnDecoration, DataTableColumn } from 'components/dataTable';
import { BadgeType } from 'components/elements/badge/types';

import { AccountAccessLevel, AccountInviteStatus } from 'types/account';

import { InvitationStatus } from './types';

export const LABELS = {
  PANEL_TITLE: ['page.profile.accounts.title', 'Accounts'],
  ROLES: {
    VIEWER: ['page.profile.accounts.roles.viewer', 'Viewer'],
    ADMINISTRATOR: ['page.profile.accounts.roles.administrator', 'Administrator'],
    OWNER: ['page.profile.accounts.roles.owner', 'Owner'],
  },
  TABLE_COLUMNS: {
    NAME: ['page.profile.accounts.table.columns.name', 'Name'],
    ROLES: ['page.profile.accounts.table.columns.roles', 'Roles'],
    INVITE_STATUS: ['page.profile.accounts.table.columns.inviteStatus', 'Status'],
    ACTIONS: ['page.profile.accounts.table.columns.actions', ''],
  },
  INVITE_STATUS: {
    [InvitationStatus.Accepted]: ['page.profile.accounts.inviteStatus.accepted', 'Active'],
    [InvitationStatus.Pending]: ['page.profile.accounts.inviteStatus.pending', 'Pending Invitation'],
  },
  ACTIONS: {
    TITLE: ['page.profile.accounts.actions.title', 'Actions'],
    SET_AS_DEFAULT: ['page.profile.accounts.actions.edit', 'Set As Default'],
    REVOKE_ACCESS: ['page.profile.accounts.actions.revokeAccess', 'Revoke Access'],
  },
  DEFAULT_ACCOUNT_BADGE: ['page.profile.accounts.defaultAccountBadge', 'Default'],
  DEFAULT_ACCOUNT_BADGE_TOOLTIP: ['page.profile.accounts.defaultAccountBadgeTooltip', 'Default account when logging in'],
  MISSING_ACCOUNTS_WARNING: ['page.profile.accounts.missingAccountsWarning', "Sorry, your profile doesn't have access to any account."],
  MISSING_ACCOUNTS_CONTACT_PHRASE: [
    'page.profile.accounts.missingAccountsContactPhrase',
    "Please contact the person in charge of your company's account if you want to be added to it.",
  ],
  REVOKE_ACCESS_MODAL: {
    TITLE: ['page.profile.accounts.revokeAccessModal.title', 'Revoke access'],
    BUTTONS: {
      CONFIRM: ['page.profile.accounts.revokeAccessModal.buttons.confirm', 'Confirm'],
      CANCEL: ['page.profile.accounts.revokeAccessModal.buttons.cancel', 'Cancel'],
    },
    CONTENT: {
      QUESTION: ['page.profile.accounts.revokeAccessModal.content.question', 'Are you sure?'],
      REMARK: ['page.profile.accounts.revokeAccessModal.content.remark', 'You’ll lose access to the {{accountName}} account.'],
    },
  },
};

export const COLUMN_IDS = {
  NAME: 'name',
  ROLES: 'roles',
  INVITE_STATUS: 'inviteStatus',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.NAME, label: t(LABELS.TABLE_COLUMNS.NAME), decoration: ColumnDecoration.bold },
  { id: COLUMN_IDS.ROLES, label: t(LABELS.TABLE_COLUMNS.ROLES) },
  { id: COLUMN_IDS.INVITE_STATUS, label: t(LABELS.TABLE_COLUMNS.INVITE_STATUS) },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];

export const partnerAccessToLabelMap: Record<AccountAccessLevel, string> = {
  [AccountAccessLevel.Viewer]: t(LABELS.ROLES.VIEWER),
  [AccountAccessLevel.Administrator]: t(LABELS.ROLES.ADMINISTRATOR),
  [AccountAccessLevel.Owner]: t(LABELS.ROLES.OWNER),
};

export const inviteStatusToLabelMap: Partial<Record<AccountInviteStatus, string>> = {
  [AccountInviteStatus.Accepted]: t(LABELS.INVITE_STATUS.ACCEPTED),
  [AccountInviteStatus.Pending]: t(LABELS.INVITE_STATUS.PENDING),
};

export const inviteStatusBadgeTypeMap: Partial<Record<AccountInviteStatus, BadgeType>> = {
  [AccountInviteStatus.Pending]: BadgeType.Default,
  [AccountInviteStatus.Accepted]: BadgeType.Success,
};
