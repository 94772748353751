import { Theme, useMediaQuery } from '@mui/material';
import React, { FunctionComponent } from 'react';

import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { DesktopCellBlock, MobileCellBlock } from './accountIdCell.styles';

export const AccountIdCell: FunctionComponent<DataTableCellProps> = ({ row, column }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const columnName = column.id;
  const cellValue = row[columnName];

  const CellBlock = isDesktop ? DesktopCellBlock : MobileCellBlock;

  return (
    <TableCell>
      <CellBlock>{cellValue}</CellBlock>
    </TableCell>
  );
};
