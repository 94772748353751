import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import passwordCreatedImage from 'assets/images/accountCreated.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { FormField } from 'components/form/formField/formField';
import IconCross from 'components/icons/cross';
import { IconKey } from 'components/icons/key';
import { Input, InputType } from 'components/inputs';
import Modal from 'components/modal/modal';

import { KEYS, LABELS } from '../../keys';
import {
  Adornment,
  Buttons,
  Delimiter,
  FirstRequirementsList,
  Heading,
  Label,
  ModalControls,
  ModalRoot,
  PanelContent,
  PasswordChangedImg,
  PasswordSection,
  RequirementsListItem,
  RequirementsSection,
  SecondRequirementsList,
  SuccessContainer,
  TextBlock,
} from './styles';
import { ChangePasswordFormFields, ChangePasswordStep } from './types';

export interface ChangePasswordModalProps {
  error?: Record<string, string>;
  onChangePassword: (formValues: ChangePasswordFormFields) => Promise<boolean>;
  passwordFields: ChangePasswordFormFields;
  isSubmitting: boolean;
  open: boolean;
  onClose: () => void;
  onFinish: () => void;
}

export const ChangePasswordModal = ({
  open,
  onClose,
  onFinish,
  isSubmitting,
  error,
  onChangePassword,
  passwordFields,
}: ChangePasswordModalProps) => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState<ChangePasswordStep>(ChangePasswordStep.Form);
  const [formValues, setFormValues] = useState(passwordFields);

  const handleFormValueChange = (fieldName: string, value: any) => {
    setFormValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const closeModal = () => {
    if (isSubmitting) {
      return;
    }

    onClose();
  };

  const handleChangePassword = async () => {
    if (isSubmitting) {
      return;
    }

    const response = await onChangePassword(formValues);
    if (response) {
      goToSuccessStep();
    } else {
      goToFormStep();
    }
  };

  const goToFormStep = () => {
    setCurrentStep(ChangePasswordStep.Form);
  };

  const goToSuccessStep = () => {
    setCurrentStep(ChangePasswordStep.Success);
  };

  const allFieldsNonEmpty = Object.values(formValues).every((fieldValue) => fieldValue.toString().trim() !== '');

  const getModalContent = () => {
    // TODO: get rid of switch
    switch (currentStep) {
      case ChangePasswordStep.Success:
        return (
          <SuccessContainer>
            <PasswordChangedImg src={passwordCreatedImage} />
            <Heading>{t(LABELS.CHANGE_PASSWORD_MODAL.SUCCESS_STEP.TITLE)}</Heading>

            <div>
              <TextBlock>{t(LABELS.CHANGE_PASSWORD_MODAL.SUCCESS_STEP.TEXT_LINE_1)}</TextBlock>
              <TextBlock>{t(LABELS.CHANGE_PASSWORD_MODAL.SUCCESS_STEP.TEXT_LINE_2)}</TextBlock>
              <TextBlock>{t(LABELS.CHANGE_PASSWORD_MODAL.SUCCESS_STEP.TEXT_LINE_3)}</TextBlock>
            </div>
          </SuccessContainer>
        );

      default:
        return (
          <>
            <PasswordSection>
              <Label>{t(LABELS.CHANGE_PASSWORD_MODAL.ENTER_NEW_PASSWORD)}</Label>
              <FormField error={error?.newPassword}>
                <Input
                  type={InputType.Password}
                  placeholder={t(LABELS.CHANGE_PASSWORD_MODAL.NEW_PASSWORD.PLACEHOLDER)}
                  value={formValues.newPassword}
                  onChange={(value) => handleFormValueChange(KEYS.FORM_FIELDS.NEW_PASSWORD, value)}
                  startAdornment={
                    <Adornment>
                      <IconKey />
                    </Adornment>
                  }
                />
              </FormField>

              <FormField error={error?.newPasswordRepeat}>
                <Input
                  type={InputType.Password}
                  placeholder={t(LABELS.CHANGE_PASSWORD_MODAL.NEW_PASSWORD_REPEAT.PLACEHOLDER)}
                  value={formValues.newPasswordRepeat}
                  onChange={(value) => handleFormValueChange(KEYS.FORM_FIELDS.NEW_PASSWORD_REPEAT, value)}
                  startAdornment={
                    <Adornment>
                      <IconKey />
                    </Adornment>
                  }
                />
              </FormField>
            </PasswordSection>

            <RequirementsSection>
              <div>{t(LABELS.CHANGE_PASSWORD_MODAL.PASSWORD_REQUIREMENTS.REMARK)}:</div>

              <FirstRequirementsList>
                <RequirementsListItem>{t(LABELS.CHANGE_PASSWORD_MODAL.PASSWORD_REQUIREMENTS.PASSWORD_LENGTH)}</RequirementsListItem>
                <RequirementsListItem>
                  {t(LABELS.CHANGE_PASSWORD_MODAL.PASSWORD_REQUIREMENTS.PASSWORD_STRENGTH_PHRASE)}:
                </RequirementsListItem>
              </FirstRequirementsList>

              <SecondRequirementsList>
                <RequirementsListItem>
                  {t(LABELS.CHANGE_PASSWORD_MODAL.PASSWORD_REQUIREMENTS.PASSWORD_STRENGTH_REQUIREMENT_1)}
                </RequirementsListItem>
                <RequirementsListItem>
                  {t(LABELS.CHANGE_PASSWORD_MODAL.PASSWORD_REQUIREMENTS.PASSWORD_STRENGTH_REQUIREMENT_2)}
                </RequirementsListItem>
                <RequirementsListItem>
                  {t(LABELS.CHANGE_PASSWORD_MODAL.PASSWORD_REQUIREMENTS.PASSWORD_STRENGTH_REQUIREMENT_3)}
                </RequirementsListItem>
                <RequirementsListItem>
                  {t(LABELS.CHANGE_PASSWORD_MODAL.PASSWORD_REQUIREMENTS.PASSWORD_STRENGTH_REQUIREMENT_4)}
                </RequirementsListItem>
              </SecondRequirementsList>
            </RequirementsSection>
          </>
        );
    }
  };

  const getModalButtons = () => {
    // TODO: get rid of switch
    switch (currentStep) {
      case ChangePasswordStep.Success:
        return (
          <Buttons>
            <Button primary onClick={onFinish}>
              {t(LABELS.CHANGE_PASSWORD_MODAL.BUTTONS.CLOSE)}
            </Button>
          </Buttons>
        );

      default:
        return (
          <Buttons>
            <Button secondary onClick={closeModal} disabled={isSubmitting}>
              {t(LABELS.CHANGE_PASSWORD_MODAL.BUTTONS.CANCEL)}
            </Button>
            <Button primary onClick={handleChangePassword} disabled={isSubmitting || !allFieldsNonEmpty}>
              {t(LABELS.CHANGE_PASSWORD_MODAL.BUTTONS.CHANGE_PASSWORD)}
            </Button>
          </Buttons>
        );
    }
  };

  const modalContent = getModalContent();
  const modalButtons = getModalButtons();

  return (
    <Modal open={open} onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.CHANGE_PASSWORD_MODAL.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <PanelContent>
            {modalContent}
            <Delimiter />
            {modalButtons}
          </PanelContent>
        </Panel>
      </ModalRoot>
    </Modal>
  );
};
