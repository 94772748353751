import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { IconEnvelope } from 'components/icons/envelope';
import { Input } from 'components/inputs';

import { KEYS, LABELS } from '../keys';
import { CreatedAccount } from '../types';
import { AccountOwnerSection, AccountSection, Adornment, Label } from './form.styles';

interface CreateAccountFormProps {
  formValues: CreatedAccount;
  onFieldValueChange: (key: string, value: any) => void;
  error?: Record<string, string>;
}

export const CreateAccountModalForm = ({ formValues, error, onFieldValueChange }: CreateAccountFormProps) => {
  const { t } = useTranslation();

  const handleChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  return (
    <>
      <AccountSection>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ACCOUNT_SECTION.TITLE)}</Label>
        <FormField error={error?.accountName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.ACCOUNT_NAME)}
            value={formValues.accountName}
            onChange={handleChange(KEYS.FORM_FIELDS.ACCOUNT_NAME)}
          />
        </FormField>
      </AccountSection>

      <AccountOwnerSection>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ACCOUNT_OWNER_SECTION.TITLE)}</Label>
        <FormField error={error?.username}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.EMAIL)}
            value={formValues.username}
            onChange={handleChange(KEYS.FORM_FIELDS.EMAIL)}
            startAdornment={
              <Adornment>
                <IconEnvelope />
              </Adornment>
            }
          />
        </FormField>

        <FormField error={error?.firstName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.FIRST_NAME)}
            value={formValues.firstName}
            onChange={handleChange(KEYS.FORM_FIELDS.FIRST_NAME)}
          />
        </FormField>

        <FormField error={error?.lastName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.LAST_NAME)}
            value={formValues.lastName}
            onChange={handleChange(KEYS.FORM_FIELDS.LAST_NAME)}
          />
        </FormField>
      </AccountOwnerSection>
    </>
  );
};
