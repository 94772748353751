import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAssetListQuery } from 'services/assets';
import { useGetOrdersListQuery } from 'services/orders';
import { OrdersListQuery } from 'services/orders/types';

import { DataTableRow } from 'components/dataTable';

import RoutePath from 'router/path';

import { Customer, CustomerIPInfo } from 'types/customer';
import { TablePagination } from 'types/pagination';
import { SortOrder } from 'types/sort';

import formatNumber from 'utils/numbers';
import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { useTableSorting } from 'utils/tables/sorting';
import { useDebouncedQuery } from 'utils/tables/useDebouncedQuery';

import { COLUMN_IDS } from '../../keys';
import { OrderDataRow } from '../../types';
import { CustomerOrdersList } from './orderList';

interface CustomerOrderListContainerProps {
  customer: Customer & CustomerIPInfo;
}

export const CustomerOrderListContainer = ({ customer }: CustomerOrderListContainerProps) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<TablePagination>(getDefaultPagination());
  const { sorting, onSort } = useTableSorting({
    defaultSorting: {
      field: COLUMN_IDS.DATE,
      direction: SortOrder.desc,
    },
  });

  const ordersListQuery: OrdersListQuery = useDebouncedQuery(() => {
    const baseQuery = {
      pagination: undecoratePagination(pagination),
      sort: {
        field: sorting.orderBy,
        direction: sorting.order,
      },
      search: customer?.username,
    };
    return baseQuery;
  }, [pagination, sorting]);

  const { data, isFetching } = useGetOrdersListQuery(ordersListQuery, {
    skip: !customer,
  });

  const { data: assets = [] } = useGetAssetListQuery();

  useEffect(() => {
    if (data?.pagination) {
      setPagination(decoratePagination(data?.pagination));
    }
  }, [data?.pagination]);

  const orders: OrderDataRow[] = (data?.data || []).map((order) => {
    const fiatAsset = assets.find((asset) => asset.symbol === order.fiatCurrencyCode);
    const fiatAmount = order.fiatAmount ? formatNumber(Number(order.fiatAmount), fiatAsset?.displayDecimals) : order.fiatAmount;
    const cryptoAsset = assets.find((asset) => asset.symbol === order.cryptoCurrencyCode);
    const cryptoAmount = order.cryptoAmount ? formatNumber(Number(order.cryptoAmount), cryptoAsset?.displayDecimals) : order.cryptoAmount;
    return {
      ...order,
      id: order.uuid,
      fiatAmount,
      cryptoAmount: cryptoAmount.toString(),
    };
  });

  const onChangePage = (page: number) => {
    setPagination({
      ...pagination,
      page,
    });
  };

  const onChangeRowsPerPage = (itemsPerPage: number) => {
    setPagination({
      ...pagination,
      itemsPerPage,
      page: 1,
    });
  };

  const navigateToOrder = ({ uuid }: DataTableRow) => navigate(RoutePath.orderById(uuid));

  return (
    <CustomerOrdersList
      pagination={pagination}
      orders={orders}
      isLoading={isFetching}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      sorting={sorting}
      onRowClick={navigateToOrder}
    />
  );
};
