import { IconBook } from 'components/icons/book';
import { IconReport } from 'components/icons/report';
import { IconSettings } from 'components/icons/settings';

import i18next from 'locales/i18next';

import RoutePath from 'router/path';

import { MenuItem } from 'types/routing';

const { t } = i18next;

export const menuItems: MenuItem[] = [
  {
    label: t('Orders'),
    icon: IconReport,
    to: RoutePath.Orders.Root,
  },
  {
    label: t('Account Settings'),
    icon: IconSettings,
    to: RoutePath.AccountSettings.Root,
  },
  {
    label: t('Documentation'),
    icon: IconBook,
    openInNewWindow: true,
    to: window?.s4cConfig?.documentationUrl || '',
  },
];

export default menuItems;
