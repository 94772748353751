import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';

import { OrderChargeStatus, OrderSide, OrderStatus } from 'types/order';

import { capitalizeFirstLetter } from 'utils/string';

import { LABELS } from '../../keys';
import { OrderSectionProps } from '../../types';
import { Item, ItemName, ItemValue, SectionWrapper } from '../styles';
import { convertToBooleanString } from '../utils';

interface PaymentSectionProps extends OrderSectionProps {
  shouldShowPaymentSectionAdditionalFields: boolean;
}

const getStatusBadgeType = (status?: OrderChargeStatus) => {
  if (status === OrderChargeStatus.Success) {
    return BadgeType.Success;
  }

  if (status === OrderChargeStatus.Fail) {
    return BadgeType.Fail;
  }

  return BadgeType.Default;
};

export const PaymentSection = ({ orderData, shouldShowPaymentSectionAdditionalFields }: PaymentSectionProps) => {
  const { t } = useTranslation();

  const capturedBadgeType = orderData.charge?.captured ? BadgeType.Default : BadgeType.Fail;
  const refundedBadgeType = orderData.charge?.refunded ? BadgeType.Fail : BadgeType.Default;
  const disputedBadgeType = orderData.charge?.disputed ? BadgeType.Fail : BadgeType.Default;
  const statusBadgeType = getStatusBadgeType(orderData.charge?.status);
  const shouldShowChargeDetails = Boolean(
    shouldShowPaymentSectionAdditionalFields && orderData.charge && orderData.order.side === OrderSide.Buy,
  );
  const shouldShowErrorDetails =
    Boolean(shouldShowPaymentSectionAdditionalFields && orderData.charge?.failureIssuerDeclineCode) ||
    Boolean(orderData.order.failedStatusReason);
  const isSellChargeSuccessful = Boolean(
    orderData.order.side === OrderSide.Sell && orderData.order.status === OrderStatus.Completed && orderData.charge,
  );

  const failedStatusReason = orderData.charge?.failureMessage || orderData.order.failedStatusReason;

  // TODO: check why there are so many checks on the same condition
  return (
    <Panel label={t(LABELS.SECTIONS.PAYMENT.TITLE)}>
      <SectionWrapper>
        <OptionallyVisible visible={shouldShowPaymentSectionAdditionalFields}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.MERCHANT_ID)}</ItemName>
            <ItemValue>{orderData.order.securionMerchantId}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={shouldShowPaymentSectionAdditionalFields}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.SHIFT4_CHARGE_ID)}</ItemName>
            <ItemValue>{orderData.order.securionChargeId}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={isSellChargeSuccessful}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.STATUS)}</ItemName>
            <ItemValue>
              <Badge type={BadgeType.Success}>{capitalizeFirstLetter(OrderChargeStatus.Success)}</Badge>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.charge?.status)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.STATUS)}</ItemName>
            <ItemValue>
              <Badge type={statusBadgeType}>{capitalizeFirstLetter(orderData.charge?.status || '')}</Badge>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={shouldShowPaymentSectionAdditionalFields}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_TYPE)}</ItemName>
            <ItemValue>{capitalizeFirstLetter(orderData.charge?.objectType || '')}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_METHOD)}</ItemName>
          <ItemValue>{capitalizeFirstLetter(orderData.order.paymentMethod)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.CARD_DETAIL)}</ItemName>
          <ItemValue>
            {orderData.order.cardBrand} {orderData.order.cardLast4}
          </ItemValue>
        </Item>

        <OptionallyVisible visible={shouldShowErrorDetails}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.FAILED_STATUS_REASON)}</ItemName>
            <ItemValue>{failedStatusReason}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.charge?.failureIssuerDeclineCode)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.DECLINE_ERROR_CODE)}</ItemName>
            <ItemValue>{orderData.charge?.failureIssuerDeclineCode}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={shouldShowChargeDetails}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.CAPTURED)}</ItemName>
            <ItemValue>
              <Badge type={capturedBadgeType}>{convertToBooleanString(orderData.charge?.captured)}</Badge>
            </ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.REFUNDED)}</ItemName>
            <ItemValue>
              <Badge type={refundedBadgeType}>{convertToBooleanString(orderData.charge?.refunded)}</Badge>
            </ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.DISPUTED)}</ItemName>
            <ItemValue>
              <Badge type={disputedBadgeType}>{convertToBooleanString(orderData.charge?.disputed)}</Badge>
            </ItemValue>
          </Item>
        </OptionallyVisible>
      </SectionWrapper>
    </Panel>
  );
};
