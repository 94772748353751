import { DataTableRow } from 'components/dataTable';

import { AccountInviteStatus } from 'types/account';

export type AccountDataRow = DataTableRow & {
  uuid: string;
  name: string;
  roles: string;
  inviteStatus: AccountInviteStatus;
  isDefault?: boolean;
};

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
}
