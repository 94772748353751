import { t } from 'i18next';

import { getDefaultDatePresets } from 'components/filters/date/keys';
import {
  AmountFilterPassedProps,
  CheckboxFilterPassedProps,
  CurrencyFilterPassedProps,
  DateFilterPassedProps,
  RadioFilterPassedProps,
  RemoteSelectFilterPassedProps,
} from 'components/filters/types';
import { CurrencyItem } from 'components/inputs/select/currencySelect/types';
import { SelectItem } from 'components/inputs/select/select';

import { Filter, FilterSection, FilterType } from 'types/filters';
import { OrderSide } from 'types/order';
import { RequestPagination, ServerPagination } from 'types/pagination';

import { LABELS, filterStatusGroups } from './keys';
import { Filters } from './types';

export const makeFilterSections = (filterMap: Record<string, Filter<unknown>>): FilterSection[] => {
  return [
    {
      key: 'filters',
      content: [filterMap.statuses, filterMap.date],
    },
    {
      key: 'currencyFilters',
      content: [filterMap.cryptoCurrencyCode, filterMap.cryptoAmount, filterMap.fiatCurrencyCode, filterMap.fiatAmount],
    },
    {
      key: 'partnerFilters',
      content: [filterMap.accountUuids],
    },
  ];
};

export const makeFilters = ({
  cryptoOptions,
  fiatOptions,
  getAccounts,
}: {
  cryptoOptions: CurrencyItem[];
  fiatOptions: CurrencyItem[];
  getAccounts: (params: {
    search?: string;
    pagination?: RequestPagination;
    ids?: string | string[];
  }) => Promise<{ data: SelectItem[]; pagination?: ServerPagination }>;
}): Record<string, Filter<unknown>> => {
  const orderSideFilter: Filter<RadioFilterPassedProps> = {
    key: 'side',
    label: t(LABELS.FILTERS.SIDE.LABEL),
    type: FilterType.Radio,
    options: [
      {
        key: OrderSide.Buy,
        value: OrderSide.Buy,
        label: t(LABELS.FILTERS.SIDE.BUY),
      },
      {
        key: OrderSide.Sell,
        value: OrderSide.Sell,
        label: t(LABELS.FILTERS.SIDE.SELL),
      },
    ],
  };

  const statusFilter: Filter<CheckboxFilterPassedProps> = {
    key: 'statuses',
    label: t(LABELS.FILTERS.STATUS.LABEL),
    type: FilterType.Checkbox,
    options: [
      {
        key: 'success',
        value: filterStatusGroups.success,
        label: t(LABELS.FILTERS.STATUS.SUCCESS),
      },
      {
        key: 'pending',
        value: filterStatusGroups.pending,
        label: t(LABELS.FILTERS.STATUS.PENDING),
      },
      {
        key: 'failed',
        value: filterStatusGroups.failed,
        label: t(LABELS.FILTERS.STATUS.FAILED),
      },
    ],
  };

  const datePresets = getDefaultDatePresets();

  const dateFilter: Filter<DateFilterPassedProps> = {
    key: 'date',
    label: t(LABELS.FILTERS.DATE.LABEL),
    type: FilterType.Date,
    presets: datePresets,
  };

  const cryptoCurrencyFilter: Filter<CurrencyFilterPassedProps> = {
    key: 'cryptoCurrencyCode',
    type: FilterType.CurrencySelect,
    label: t(LABELS.FILTERS.CRYPTO_CURRENCY.LABEL),
    options: cryptoOptions,
    multiple: false,
  };

  const cryptoCurrencyAmountFilter: Filter<AmountFilterPassedProps> = {
    key: 'cryptoAmount',
    type: FilterType.Amount,
    label: t(LABELS.FILTERS.CRYPTO_AMOUNT.LABEL),
  };

  const fiatCurrencyFilter: Filter<CurrencyFilterPassedProps> = {
    key: 'fiatCurrencyCode',
    type: FilterType.CurrencySelect,
    label: t(LABELS.FILTERS.FIAT_CURRENCY.LABEL),
    options: fiatOptions,
    multiple: false,
  };

  const fiatCurrencyAmountFilter: Filter<AmountFilterPassedProps> = {
    key: 'fiatAmount',
    type: FilterType.Amount,
    label: t(LABELS.FILTERS.FIAT_AMOUNT.LABEL),
  };

  const accountFilter: Filter<RemoteSelectFilterPassedProps> = {
    key: 'accountUuids',
    label: t(LABELS.FILTERS.PARTNER.LABEL),
    type: FilterType.RemoteSelect,
    placeholder: t(LABELS.FILTERS.PARTNER.PLACEHOLDER),
    multiple: false,
    getItems: getAccounts,
  };

  return [
    orderSideFilter,
    statusFilter,
    dateFilter,
    cryptoCurrencyFilter,
    cryptoCurrencyAmountFilter,
    fiatCurrencyFilter,
    fiatCurrencyAmountFilter,
    accountFilter,
  ].reduce((acc, cur) => {
    return { ...acc, [cur.key]: cur };
  }, {});
};

export const prepareFilters = (filters: Filters) => {
  const output = {
    ...filters,
  };

  if (filters.accountUuids) {
    output.accountUuids = Array.isArray(filters.accountUuids) ? filters.accountUuids : [filters.accountUuids];
  }

  return output;
};
