import React from 'react';
import { useTranslation } from 'react-i18next';

import { LABELS } from '../keys';
import { CreatedAccount } from '../types';
import { BoldText, Heading, TextBlock, VerifyContainer } from './verify.styles';

interface CreateAccountModalVerifyProps {
  formValues: CreatedAccount;
}

export const CreateAccountModalVerify = ({ formValues }: CreateAccountModalVerifyProps) => {
  const { t } = useTranslation();

  return (
    <VerifyContainer>
      <Heading>{t(LABELS.CREATE_MODAL.VERIFY_STEP.TITLE)}</Heading>
      <TextBlock>
        <div>{t(LABELS.CREATE_MODAL.VERIFY_STEP.ACCOUNT_NAME_LABEL)}</div>
        <BoldText>{formValues.accountName}</BoldText>
      </TextBlock>

      <TextBlock>
        <div>{t(LABELS.CREATE_MODAL.VERIFY_STEP.ACCOUNT_OWNER_LABEL)}n</div>
        <BoldText>{formValues.username}</BoldText>
        <BoldText>
          {formValues.firstName} {formValues.lastName}
        </BoldText>
      </TextBlock>
    </VerifyContainer>
  );
};
