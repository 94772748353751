import { AccountRecordInfo } from 'services/accounts/types';

import { partnerAccessToLabelMap } from './keys';
import { AccountDataRow } from './types';

export const transformAccountsDataToTableFormat = (accountRecordInfos?: AccountRecordInfo[]): AccountDataRow[] => {
  return accountRecordInfos
    ? accountRecordInfos.map((accountRecordInfo) => ({
        uuid: accountRecordInfo.account.uuid,
        name: accountRecordInfo.account.name,
        roles: partnerAccessToLabelMap[accountRecordInfo.access.level],
        inviteStatus: accountRecordInfo.access.inviteStatus,
        isDefault: accountRecordInfo.isDefault,
        id: accountRecordInfo.account.uuid,
      }))
    : [];
};
