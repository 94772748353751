import React from 'react';

import { IconProps } from './types';

const path = [
  'M13.1667 2H3.83333C3.1 2 2.5 2.6 2.5 3.33333V12.6667C2.5 13.4 3.1 14 3.83333 14H13.1667C13.9 14 14.5',
  '13.4 14.5 12.6667V3.33333C14.5 2.6 13.9 2 13.1667 2ZM13.1667',
  '12.6667H3.83333V3.33333H13.1667V12.6667ZM5.16667 6.66667H6.5V11.3333H5.16667V6.66667ZM7.83333',
  '4.66667H9.16667V11.3333H7.83333V4.66667ZM10.5 8.66667H11.8333V11.3333H10.5V8.66667Z',
].join(' ');

export const IconReport = ({ width = 17, height = 16, color, className, style }: IconProps) => (
  <svg className={className} style={style} width={width} height={height} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
    <path fill={color || 'currentColor'} d={path} />
  </svg>
);
