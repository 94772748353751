import RoutePath from 'router/path';

export const KEYS = {
  TRANSLATION_NAMESPACE: 'page.accountSettings',
  ROUTE_MATCH: `${RoutePath.GeneralSettings.Root}/:route`,
  TABS: {
    USER_MANAGEMENT: 'users',
    SETTLEMENT_RECONCILIATIONS: 'settlement-reconciliations',
  },
};

export const LABELS = {
  BREADCRUMBS: ['General Settings'],
  PAGE_TITLE: ['page.settings.title', 'General Settings'],
  TABS: {
    USER_MANAGEMENT: ['page.settings.tabs.userManagement', 'User management'],
    SETTLEMENT_RECONCILIATIONS: ['page.settings.tabs.settlementReconciliations', 'Settlement Reconciliations'],
  },
};
