import React from 'react';

import { FormField } from 'components/form/formField/formField';

import { FieldTitle, FieldValue, FormRow } from './styles';
import { FormFieldDescription } from './types';

interface FormLayoutProps {
  fields: FormFieldDescription[];
  readonly?: boolean;
  error?: Record<string, string>;
}

export const FormLayout = ({ fields, readonly, error }: FormLayoutProps) => {
  return (
    <>
      {fields
        .filter((field) => !field.hidden)
        .map((field) => (
          <FormRow key={field.key}>
            <FieldTitle>{field.label}</FieldTitle>
            <FieldValue>
              <FormField error={error?.[field.key]}>{field?.renderInput?.({ readonly })}</FormField>
            </FieldValue>
          </FormRow>
        ))}
    </>
  );
};
