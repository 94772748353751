import {
  APIKeysSectionRoute,
  AccountSettingsRoute,
  GeneralSectionRoute,
  PaymentMethodsSectionRoute,
  UsersSectionRoute,
} from 'pages/account/route';
import { DocumentationRoute } from 'pages/documentation/route';
import { OrderListRoute, OrderViewRoute } from 'pages/orders/route';
import { ProfileRoute } from 'pages/profile/route';

import { RouteSetup } from 'types/routing';

import RoutePath from '../../../path';
import { menuItems } from './menuItems';

export const PartnerAdministratorRouterSetup = (hasAccounts: boolean): RouteSetup => ({
  availableRoutes: [
    {
      path: RoutePath.Orders.Root,
      component: OrderListRoute,
    },
    {
      path: RoutePath.Orders.ById,
      component: OrderViewRoute,
    },
    {
      path: RoutePath.AccountSettings.Root,
      component: AccountSettingsRoute,
      defaultRedirectRoute: RoutePath.AccountSettings.General,
      children: [
        {
          path: RoutePath.AccountSettings.General,
          component: GeneralSectionRoute,
        },
        {
          path: RoutePath.AccountSettings.PaymentMethods,
          component: PaymentMethodsSectionRoute,
        },
        {
          path: RoutePath.AccountSettings.APIKeys,
          component: APIKeysSectionRoute,
        },
        {
          path: RoutePath.AccountSettings.Users,
          component: UsersSectionRoute,
        },
      ],
    },
    {
      path: RoutePath.Documentation.Root,
      component: DocumentationRoute,
    },
    {
      path: RoutePath.Profile.Root,
      component: ProfileRoute,
    },
  ],
  menuItems,
  defaultLoginRedirectRoute: hasAccounts ? RoutePath.Orders.Root : RoutePath.Profile.Root,
});

export default PartnerAdministratorRouterSetup;
